import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Fancy design or accessibility? The `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` pseudo-class enables you
to support keyboard-only users and, at the same time, satisfy the aesthetic expectations of your clients.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1xWXKuE8U//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMRBP/aAAgBAQABBQJ2yterJXcHU2sZs5xqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABERIQQXH/2gAIAQEABj8Cl6IqTao08Pp//8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBITFBYYH/2gAIAQEAAT8hqzYrt6M9zSKVKYOlDBM8j//aAAwDAQACAAMAAAAQG8//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8QiY//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Qq6//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUFRYXH/2gAIAQEAAT8QBGe3wzBkI2mWYWthVNfZt5lBiZDUtslmD2vhP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Closeup of a person's hands typing on a laptop keyboard",
          "title": "Closeup of a person's hands typing on a laptop keyboard",
          "src": "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg",
          "srcSet": ["/static/58a5de4c0dd0ba8473945cd4519d5b1d/f93b5/pexels-cottonbro-keyboard.jpg 300w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b4294/pexels-cottonbro-keyboard.jpg 600w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg 1200w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b17f8/pexels-cottonbro-keyboard.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © cottonbro / pexels.com`}</em></p>
    <h2>{`The Importance of Keyboard Focus`}</h2>
    <p>{`Being able to navigate with a keyboard is an important feature of accessible websites. This benefits
people with motor impairments who rely on a keyboard, instead of a mouse, to navigate a web page.
Providing a `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html"
      }}>{`visual indicator`}</a>{` that
clearly highlights the element that currently has keyboard focus is therefore indispensable.`}</p>
    <p>{`Browsers provide a `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Techniques/general/G149"
      }}>{`default focus indicator`}</a>{` to
ensure that keyboard focus is visible. In CSS, the styling of the focus indicator can be changed via
the `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{` pseudo-class. For example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button:focus {
    outline: 2px solid purple;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Design versus Accessibility`}</h2>
    <p>{`I think we can all agree that a visual focus indicator is very helpful for keyboard users. On the other hand,
the focus indicator also appears when users click an element with a mouse or touch it with their finger.
For some users, this can be distracting or even irritating. Especially, without the knowledge that highlighting
the element is actually an accessibility feature.`}</p>
    <p>{`Often, web developers have to deal with clients and designers, who insist that they remove the focus styles
altogether for purely aesthetic reasons. Consequently, many websites define CSS rules like this one:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button:focus {
    outline: none;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Please, don't do this! You would actively exclude people who depend on keyboard navigation.`}</p>
    <h2>{`The Solution is :focus-visible`}</h2>
    <p>{`There is a way to reconcile accessibility with design expectations: The `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{`
pseudo-class. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/selectors-4/#the-focus-visible-pseudo"
      }}>{`specification`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The :focus-visible pseudo-class applies while an element matches the :focus pseudo-class and
the user agent determines via heuristics that the focus should be made evident on the element.`}</p>
    </blockquote>
    <p>{`This means: It lets you show focus styles only when they are needed, that is to say, when an element receives
focus and the browser determines that the focus indicator should be shown (e.g., on keyboard navigation).`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Hide focus styles if they're not needed. */
button:focus:not(:focus-visible) {
    outline: none;
}

/* Show focus styles on keyboard focus. */
button:focus-visible {
    outline: 2px solid purple;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This way, you gain precise control over when the focus indicator of e.g., buttons and links is shown.
For some elements like input fields, the focus indicator will still be shown when the users click on it.
This is because clicking the input field activates keyboard input. You can test this behavior
in my `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/a11yfeatures"
      }}>{`demo`}</a>{`.`}</p>
    <h2>{`Browser Support`}</h2>
    <p>{`Most modern browsers already `}<a parentName="p" {...{
        "href": "https://caniuse.com/?search=focus-visible"
      }}>{`support the feature`}</a>{`.
Safari also added support for `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` with
`}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes"
      }}>{`version 15.4`}</a>{`.
Still, I'd recommend you define regular focus styles for non-supporting browsers and then overwrite them
for browsers that support `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{`.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Regular focus styles for non-supporting browsers. */
button:focus {
    outline: 2px solid purple;
}

/* For browsers that support :focus-visible */
@supports (:focus-visible) {
    button:focus:not(:focus-visible) {
        outline: none;
    }

    button:focus-visible {
        outline: 2px solid purple;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Useful Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.deque.com/blog/accessible-focus-indicators/"
        }}>{`Accessible Focus Indicators: Something to :focus on`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html"
        }}>{`WCAG 2.1 Success Criterion 2.4.7: Focus Visible`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance-minimum.html"
        }}>{`WCAG 2.2 Success Criterion 2.4.11: Focus Appearance (Minimum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible"
        }}>{`MDN page about :focus-visible`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 04/14/2022</PostUpdateTitle>
    <p>{`Post updated for Safari 15.4.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      